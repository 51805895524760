import React from 'react';
import Header from "../header";
import Footer from "../../components/common/footer";
import NvoccDetail from "../../components/common/Nvoccdetails";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useTranslation, I18nextContext,Link} from "gatsby-plugin-react-i18next";


const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL
  const Nvocc = () => {
    const { language } = React.useContext(I18nextContext)
    const buildCanonicalUrl = () =>
    language === "en"
      ? GATSBY_LANDING_PAGE_URL + "/product/nvocc-software/"
      : GATSBY_LANDING_PAGE_URL + `/${language}/product/nvocc-software/`
      const canoncalUrl = buildCanonicalUrl();

      const buildHrefUrl = () =>
      language === "en"
        ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
        : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL +`/${language}/`))
    const hrefUrl = buildHrefUrl();

      const { t } = useTranslation()
    return (
        <div className="Layout">
            <Header/>
            <NvoccDetail/>
            <Footer/>
            <Helmet>
          <script
            dangerouslySetInnerHTML={{
              __html: `
        
<!-- Google Tag Manager -->

        <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TK7M58Z');</script>
        
            `,

            }}
          />

          <script
            dangerouslySetInnerHTML={{
              __html: `
        
<!-- Google Tag Manager -->

        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TK7M58Z"
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>    
            `,
            }}
          />
          <meta charSet="utf-8" />
          <title>{t('NVOCC Software - NVOCC Freight Forwarding Solution')}</title>
          <Link rel="canonical" href={canoncalUrl} /> 
          <Link rel="alternate" href={hrefUrl} hreflang={language} /> 
          <meta name="description" content={t("Logipulse NVOCC software empowers you to manage your container flow smoothly. Our NVOCC freight forwarding software aids in keeping track of voyages and cargo, ensuring the financial aspects of P&L statements and the profit margin for each leg of the voyage are kept updated.")} />        
        </Helmet>

        </div>
    )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Nvocc;