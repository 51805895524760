import React, { Component } from "react";
import "../../styles/threeplwarehousing.scss";
import Photo from "../Photo";
import { graphql } from "gatsby";
import { Trans, I18nextContext, Link } from "gatsby-plugin-react-i18next";
import { withTranslation } from 'react-i18next';


class threeplwarehouse extends Component {
  static contextType = I18nextContext;
  constructor() {
    super()
    this.state = {
      showMe: true,
      multiInterface: true,
    }
  }
  Schedule(e, curObj) {
    var status = false;
    if (document.getElementById('feature__' + curObj).classList.contains('active_color')) {
      document.getElementById('feature__' + curObj).classList.remove('active_color');
      document.getElementById('arrow__' + curObj).classList.remove('active_color');
      document.getElementById('feature__' + curObj).classList.add('inactive_color');
      document.getElementById('arrow__' + curObj).classList.add('inactive_color');
      status = false;
    } else {
      document.getElementById('feature__' + curObj).classList.add('active_color');
      document.getElementById('arrow__' + curObj).classList.add('active_color');
      document.getElementById('feature__' + curObj).classList.remove('inactive_color');
      document.getElementById('arrow__' + curObj).classList.remove('inactive_color');
      status = true;
    }

    var custObj = {};
    custObj[curObj] = status;
    this.setState(custObj);
  }
  render() {
    return (
      <div>
        <div className="banner-feature">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="content-imgs">
                  <div className="pricings_in_details shipping_agency_main" lang={I18nextContext._currentValue.language}>
                    <Link to="/"><Trans>HOME</Trans></Link> / <div className="featuress"><Link to="/product/"><Trans>PRODUCTS</Trans></Link> / </div>
                    <div className="warehouse"><Trans>NVOCC</Trans></div>
                  </div>
                  <div className="main_shipping_cont" lang={I18nextContext._currentValue.language}><Trans>Making carrier availability and related services easier for you to handle!</Trans></div>
                  <div className="warehouse_third_content" lang={I18nextContext._currentValue.language}>
                    <Trans>Starting with your periodical carrier space booking to generating documents of Bill of lading, things are getting smoother for you from now on.</Trans>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="shiping_agency">
          <div className="container">
            <div className="row ">

              <div className="col-md-12 full_row_alignment">
                <div className="fullimage">
                  <Photo
                    src="Shipping Agency.jpg"
                    className="dimention_image"
                    alt=""
                  />
                </div>
                <div className="threepl_contents" lang={I18nextContext._currentValue.language}>
                
                <h1 className="heading-size"><Trans>NVOCC</Trans></h1>
                  <Trans>A digitized way of planning and execution of container flow.</Trans>
                </div>
                <div className="threeplsub_headings" lang={I18nextContext._currentValue.language}>
                  <Trans>As an NVOCC manages and leases out space on a container, it is likely that there will be many such leases, of several containers on many sea faring vessels plying the various sea routes around the globe.</Trans><br></br><br></br>
                  <Trans>Logipulse aids in keeping track of not only these voyages and cargo but also ensuring that the financial aspects of P&L statements as well as the profit margin for each leg of the voyage are kept updated. Managing your container flow is easier and hassle free.</Trans>
                </div>

              </div>
            </div>
          </div>
        </div>



        <div className="highlights_way" lang={I18nextContext._currentValue.language}>
          <div className="container">

            <div className="Hight_light_heads_content"><Trans>Highlights</Trans></div>

            <div className="row full_row_aligns">
              <div className="col-md-6">
                <ul>


                  <li>

                    <div className="material" id="arrow__multiInterface" onClick={e => this.Schedule(e, 'multiInterface')} style={{ cursor: "pointer" }}>
                      {
                        this.state.multiInterface ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }
                    </div>

                    <h5 className="active_color" id="feature__multiInterface" onClick={e => this.Schedule(e, 'multiInterface')}>
                      <Trans>Multilingual Interface</Trans>
                    </h5>
                    {
                      this.state.multiInterface ? (

                        <div className="subheadings">
                          <Trans>As an NVOCC you would have dealings around the world and maybe also have port of calls around the world.  Being multilingual is an advantage you’d definitely want to have. Logipulse’s Multilingual interface allows you to switch between all the major languages as easily as a click of a button.</Trans>
                          <div className="know_more">
                            <b><Link to="/features/#multilingual" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                            <Photo
                              src="KnowMore-Right Arrow-H12.svg"
                              class="testing-arrow"
                              alt=""
                            />
                          </div>
                        </div>
                      )
                        : null
                    }
                  </li>


                  <li>
                    <div className="material" id="arrow__rack" onClick={e => this.Schedule(e, 'rack')} style={{ cursor: "pointer" }}>
                      {
                        this.state.rack ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }</div>
                    <h5 id="feature__rack" onClick={e => this.Schedule(e, 'rack')}>
                      <Trans>Multicurrency</Trans>
                    </h5>
                    {this.state.rack ? (
                      <div className="subheadings">
                        <Trans>With different port of calls and languages comes different currencies. Like our Multilingual interface, Logipulse is also designed to cater to the various different currencies and ROE that an NVOCC would have to face as part of its day-to-day operations. You can easily handle currency management. Logipulse will handle 3 levels of currency and can also review the financial statements in any currency selected. For example, let’s say your branch is in UAE. At the time of generating the invoice, you can select the local currency (AED) or a currency which the customer wants (eg. USD) and the payment can be done in the currency of the invoice (either AED or USD as selected) or you can opt for a third new currency (eg. Euro.)</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#multicurrency" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null
                    }
                  </li>


                  <li>
                    <div className="material" id="arrow__Automated" onClick={e => this.Schedule(e, 'Automated')} style={{ cursor: "pointer" }}>
                      {
                        this.state.Automated ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }

                    </div>
                    <h5 id="feature__Automated" onClick={e => this.Schedule(e, 'Automated')}>
                      <Trans>Quotes</Trans>
                    </h5>
                    {this.state.Automated ? (
                      <div className="subheadings">
                        <Trans>Logipulse quotation management module lets you create and send professional digital quotes to your customers instantly. Display a quotation form for walk-in inquiries and include all contract rates from carriers. You can convert a CRM sales lead into a quote by arranging a follow-up discussion with the customer. You can supervise all your existing and pending quotes approval all under one platform transparently.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#Quotes" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null
                    }
                  </li>


                  <li>
                    <div className="material" id="arrow__Document" onClick={e => this.Schedule(e, 'Document')} style={{ cursor: "pointer" }}>
                      {
                        this.state.Document ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }

                    </div>
                    <h5 id="feature__Document" onClick={e => this.Schedule(e, 'Document')}>
                      <Trans>Voyages</Trans>
                    </h5>
                    {this.state.Document ? (
                      <div className="subheadings">
                        <Trans>With this module, you can create and schedule your voyages quickly. Keep track of the containers and vessels. Also, have the advantage of getting all the related documents instantly, house waybill, bill of lading, multi-currency details, item details, pre and post port transportation, etc. You can keep internal notes, manifest reports, other records, and job info while creating or scheduling the job.</Trans>
                        {/* <div className="know_more">
                          <b><Trans>KNOW MORE</Trans></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div> */}
                      </div>
                    ) : null
                    }
                  </li>


                  <li>
                    <div className="material" id="arrow__Customerportal" onClick={e => this.Schedule(e, 'Customerportal')} style={{ cursor: "pointer" }}>
                      {
                        this.state.Customerportal ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }


                    </div>
                    <h5 id="feature__Customerportal" onClick={e => this.Schedule(e, 'Customerportal')}>
                      <Trans>Integrated Finance Module</Trans>
                    </h5>
                    {this.state.Customerportal ? (
                      <div className="subheadings">
                        <Trans>One of the unique features of Logipulse is its fully functional integrated Finance Module with the Operations Module. Whenever a charge is posted in the operations module, it will automatically reflect in the Finance module by posting accounting entries in the background. The same transactions can be seen by using our wide range of reports from Ledger to Balance Sheet including Sundry Debtors and Creditors, Actor Specific Ledger, General Ledger, Cash Flow Statements, Trial Balance, Profit & Loss and Balance Sheet. These reports are available both at Branch and Headquarter level.</Trans><br></br><br></br>
                        <Trans>To know the financial status of the customers and vendors we have reports such as Aging Report, Outstanding Reports, Statement of Accounts and Payment Summary.</Trans><br></br><br></br>
                        <Trans>While maintaining a specific ledger for every walk-in customer or vendor, the chart of accounts will get bulky or enormous. So instead, Logipulse manages all the actor accounts through the concept of Virtual Ledger.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#IntegratedFinance" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null
                    }
                  </li>


                  <li>
                    <div className="material" id="arrow__Sharing" onClick={e => this.Schedule(e, 'Sharing')} style={{ cursor: "pointer" }}>
                      {
                        this.state.Sharing ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }


                    </div>
                    <h5 id="feature__Sharing" onClick={e => this.Schedule(e, 'Sharing')}>
                      <Trans>Data Sharing with Agencies and Branches</Trans>
                    </h5>
                    {this.state.Sharing ? (
                      <div className="subheadings">
                        <Trans>With Logipulse you are getting a centralized data storage that is accessible to you, your agencies, and the branches in real-time without a local server or backup facility, or security system. Every hassle of these kinds of technical aspects, for instance, network, firewall, connectivity, backup, etc. will be managed by Logipulse for you. Even modified information can be shared on this platform. You don't have to worry about files getting lost or sending every document physically with your associates.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#DataSharing" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null
                    }
                  </li>



                  <li>
                    <div className="material" id="arrow__Management" onClick={e => this.Schedule(e, 'Management')} style={{ cursor: "pointer" }}>
                      {
                        this.state.Management ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }

                    </div>
                    <h5 id="feature__Management" onClick={e => this.Schedule(e, 'Management')}>
                      <Trans>Consolidation Management</Trans>
                    </h5>
                    {this.state.Management ? (
                      <div className="subheadings">
                        <Trans>Logipulse provides you the facility to optimize consolidation within a short duration. You will be able to utilize the space in the carrier effectively and cost-efficiently with the technology of advanced algorithms. You get the advantage to generate pre-alerts easily. Pre-alerts include house waybill, master waybill, manifest documents, etc.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#DirectShipments" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null
                    }
                  </li>

                  <li>
                    <div className="material" id="arrow__Tariff" onClick={e => this.Schedule(e, 'Tariff')} style={{ cursor: "pointer" }}>
                      {
                        this.state.Tariff ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }


                    </div>
                    <h5 id="feature__Tariff" onClick={e => this.Schedule(e, 'Tariff')}>
                      <Trans>Automated Tariff Prediction</Trans>
                    </h5>
                    {this.state.Tariff ? (
                      <div className="subheadings">
                        <Trans>Logipulse can help you gather the tariffs so that you can pass your charges instantly including your margin. You can speed up your operations as well as customer relations with the assistance of Logipulse automated tariff prediction.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#Accounting" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null
                    }
                  </li>



                  <li>
                    <div className="material" id="arrow__Rates" onClick={e => this.Schedule(e, 'Rates')} style={{ cursor: "pointer" }}>
                      {
                        this.state.Rates ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }
                    </div>
                    <h5 id="feature__Rates" onClick={e => this.Schedule(e, 'Rates')}>
                      <Trans>Charge Allotment</Trans>
                    </h5>
                    {this.state.Rates ? (
                      <div className="subheadings">
                        <Trans>When you have a consolidated carrier, you get the charges of it divided into different jobs automatically. This will be done accurately according to the predefined conditions. With the charge allotment feature, you will get the idea of whether your service is a profit or loss.</Trans>
                        {/* <div className="know_more">
                          <b><Trans>KNOW MORE</Trans></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div> */}
                      </div>
                    ) : null
                    }
                  </li>


                  <li>
                    <div className="material" id="arrow__Freight" onClick={e => this.Schedule(e, 'Freight')} style={{ cursor: "pointer" }}>
                      {
                        this.state.Freight ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }

                    </div>
                    <h5 id="feature__Freight" onClick={e => this.Schedule(e, 'Freight')}>
                      <Trans>Integrated State Updates</Trans>
                    </h5>
                    {this.state.Freight ? (
                      <div className="subheadings">
                        <Trans>Logipulse provides you with a dedicated and most intelligent state engine that can track all the entities in your NVOCC business. It gives you separate updates, for instance, container, carrier, etc. Whenever a state is changed for a carrier or any operation, an alert will be generated to you, your employees, consignee, or any other required set of people as SMS and emails. You will know whether a task assigned is started, partially completed, or completed.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#IntegratedState" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null
                    }
                  </li>


                  <li>
                    <div className="material" id="arrow__Excel" onClick={e => this.Schedule(e, 'Excel')} style={{ cursor: "pointer" }}>
                      {
                        this.state.Excel ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }

                    </div>
                    <h5 id="feature__Excel" onClick={e => this.Schedule(e, 'Excel')}>
                      <Trans>Automated Documents Generation</Trans>
                    </h5>
                    {this.state.Excel ? (
                      <div className="subheadings">
                        <Trans>You can easily generate any document involved in your operation with Logipulse. Different versions of documents can also be produced. Documents can be shared between agencies and can be emailed to desired people. Editable templates are available for you to create documents instantly. There are numerous documents required from port-to-port movement, automating and customizing it accordingly will make the process professional, accurate and fast.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#AutomatedDocuments" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null
                    }
                  </li>



                </ul>
              </div>

              <div className="col-md-6 second_row_align">
                <ul>


                  <li>
                    <div className="material" id="arrow__bin" onClick={e => this.Schedule(e, 'bin')} style={{ cursor: "pointer" }}>
                      {
                        this.state.bin ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }

                    </div>
                    <h5 id="feature__bin" onClick={e => this.Schedule(e, 'bin')}>
                      <Trans>Task Approvals and Permissions</Trans>
                    </h5>
                    {this.state.bin ? (
                      <div className="subheadings">
                        <Trans>Nothing goes without the knowledge and approval of the authorized person you assigned. Every small modification made in the business or organization will be notified for approvals and permissions. The subsequent action will take effect only after it. Logipulse can handle approvals, permissions, and climb-up messages in case a person misses a notification throughout the operation.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#Taskapproval" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null
                    }
                  </li>







                  <li>
                    <div className="material" id="arrow__Movement" onClick={e => this.Schedule(e, 'Movement')} style={{ cursor: "pointer" }}>
                      {
                        this.state.Movement ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }


                    </div>
                    <h5 id="feature__Movement" onClick={e => this.Schedule(e, 'Movement')}>

                      <Trans>Events Notifications and Climb-up Messages</Trans>
                    </h5>
                    {this.state.Movement ? (
                      <div className="subheadings">
                        <Trans>All logistics events are tracked in Logipulse. All event notifications can be configured according to your requirement. You can decide when and to whom you want to notify. Events and notifications will appear in that person's inbox. In case the message is ignored or overlooked by the person the notification will climb up to the higher employee.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#Alertengine" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null
                    }
                  </li>


                  <li>
                    <div className="material" id="arrow__pdaDevice" onClick={e => this.Schedule(e, 'pdaDevice')} style={{ cursor: "pointer" }}>
                      {
                        this.state.pdaDevice ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }


                    </div>
                    <h5 id="feature__pdaDevice" onClick={e => this.Schedule(e, 'pdaDevice')}>
                      <Trans>Customizable Reports</Trans>
                    </h5>
                    {this.state.pdaDevice ? (
                      <div className="subheadings">
                        <Trans>All operational reports and financial reports can be customized. You may have to customize reports at times. The application lets you customize reports with a basic knowledge of HTML or with the guidance of a help document.</Trans>
                        {/* <div className="know_more">
                          <b><Trans>KNOW MORE</Trans></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div> */}
                      </div>
                    ) : null
                    }
                  </li>

                  <li>
                    <div className="material" id="arrow__alert" onClick={e => this.Schedule(e, 'alert')} style={{ cursor: "pointer" }}>
                      {
                        this.state.alert ? (
                          <Photo src="arrow_circle_up_black_24dp.svg" alt="" />

                        )

                          : <Photo src="arrow_circle_down_black_24dp (1).svg" alt="" />
                      }


                    </div>
                    <h5 id="feature__alert" onClick={e => this.Schedule(e, 'alert')}>

                      <Trans>Well Defined CRM Module</Trans>
                    </h5>
                    {this.state.alert ? (
                      <div className="subheadings">
                        <Trans>You can build up your customer circle using the facilities provided in the Logipulse CRM module. It will be easier for you to follow up enquiries and quotes. You can study how you achieved a new customer and a lot more from this section. Thus, you will be able to elaborate your customer list.</Trans>
                        <div className="know_more">
                          <b><Link to="/features/#CRM" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                          <Photo
                            src="KnowMore-Right Arrow-H12.svg"
                            class="testing-arrow"
                            alt=""
                          />
                        </div>
                      </div>
                    ) : null
                    }
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>

        <div class="end_divis_shipingagencydetails">
          <div className="header_contents_sections text-right"></div>
          <div className="container">
            <div className="row">
              <div class="col-sm-12">
                <Photo
                  src="Bottom Image-11.svg"
                  class="innerimage"
                  alt=""
                />
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withTranslation()(threeplwarehouse)

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
